<template>
  <div class="main-content auth">
    <div class="auth-container box">
      <Switch :switchValue="switchValue" @switchValueChanged="switchValue = !switchValue" />
      <img class="auth__logo" src="@/assets/images/logos/LogoMeaning.svg">
      <div class="auth__titles">
        <h2>{{ $t('forgotpassword.recuperarcontra') }}</h2>
        <p class="subtitle">{{ $t('forgotpassword.ingrescorreop1') }} <span class="meaning">{{
        $t('forgotpassword.ingresacorreop2') }}</span> {{ $t('forgotpassword.ingresacorreop3') }}</p>
      </div>
      <div class="auth__form">
        <div class="auth__own">
          <form @submit.prevent="requestPasswordRecovery()">
            <div class="form__group">
              <label class="form__label" for="email">{{ $t('forgotpassword.correo') }}</label>
              <input class="form__input" id="email" type="email" v-model="email" required autofocus>
              <!-- <span class="form__input-info"></span> -->
            </div>
            <div class="form__actions">
              <button class="btn btn--primary btn--block" type="submit" :disabled="!email">{{
        $t('forgotpassword.enviar') }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Switch from '../components/Switch.vue'
export default {
  components: { Switch },
  name: 'RequestPasswordRecovery',
  data() {
    return {
      email: '',
      switchValue: true
    }
  },
  methods: {
    async requestPasswordRecovery() {
      const res = await this.$axios.post('auth/request-password-recovery', {
        email: this.email
      })
      if (res.data.ok) {
        alert(this.$t('forgotpassword.alertmsg'))
      }
      this.$router.push('/')
    }
  }
}
</script>
